html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  background-color: #000;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
#root {
  width: 100%;
  display: flex;
}
.App {
  width: 100%;
  display: flex;
}

.profession {
  flex: 0 0 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafbfd;

  span.lazy-load-image-background {
    width: 250px;
    min-width: 250px;
    img {
      border: 3px solid #34495d;
      border-radius: 50%;
      width: 250px;
      min-width: 250px;
    }
  }

  h2 {
    font-weight: 500;
    margin-bottom: 0;
    text-align: center;
  }
  h4 {
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: center;
  }
  h5 {
    font-weight: 500;
    margin-top: 0;
    text-align: center;
  }
}
.social {
  justify-self: flex-end;
  a {
    color: #000;
    font-size: 25px;
    margin: 5px 10px;
    text-decoration: none;
  }
}
@media (max-width: 480px) {
  .profession {
    width: 70%;
  }
}

.person {
  flex: 0 0 50%;
  height: 100%;
  background: center / contain no-repeat
    url(https://images.unsplash.com/photo-1525340581945-d5e2b09641c4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2400&q=80);
  background-size: cover;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h2,
  h3 {
    font-style: italic;
    font-weight: 500;
    margin: 5px 0;
    text-align: center;
  }

  h2 {
    margin-top: 70px;
  }

  .social {
    margin-top: 20px;
  }

  span {
    color: rgb(29, 62, 44);
    position: absolute;
    bottom: 5px;
    right: 5px;

    a {
      text-decoration: none;
      color: rgb(29, 62, 44);
    }
  }
}
